/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import { useDevice, useWindowDimensions } from "~hooks";
import * as T from "~styles/Typography.jsx";
import Grid, { GRID_PADDING_PX } from "~styles/Grid.jsx";
import { LineSwipe } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const TableTextSection = ({
  data: {
    backgroundColor,
    backgroundColor2,
    headingFontColor,
    fontColor,
    gridColor,
    subheading,
    heading,
    body,
    alignment,
    footnote,
    table,
    tableFootnote
  }
}) => {
  const { isDesktop } = useDevice();

  // ---------------------------------------------------------------------------
  // lifecycle

  //

  // ---------------------------------------------------------------------------
  // render

  const isLeft = alignment === `left`;

  return (
    <div
      id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          background-color: ${backgroundColor?.hex || `#f5f6fa`};
        `,
        tw`w-full relative overflow-hidden`
      ]}
    >
      {/* desktop (half) background */}
      <div
        css={[
          css`
            display: none;

            ${MEDIA_QUERIES.desktop} {
              width: 53.25vw; // feels good man
              display: block;
              background-color: ${backgroundColor2?.hex || `#f5f6fa`};
              ${isLeft ? `left: 0;` : `right: 0;`};
            }
          `,
          tw`h-full absolute top-0 bottom-0`
        ]}
      />

      <Grid>
        {/* text content */}
        <div
          css={[
            css`
              ${MEDIA_QUERIES.desktop} {
                order: ${isLeft ? `2` : `1`};
              }
            `,
            tw`col-span-12 md:col-span-5 relative pt-16 md:pt-16 lg:pt-20 pb-24`,
            isLeft ? tw`md:col-start-8` : tw`md:col-start-1`
          ]}
        >
          <div css={[css``, tw`relative`]}>
            <header
              css={[
                css`
                  color: ${headingFontColor?.hex || `#002762`};
                `,
                tw`relative flex items-center mb-10 md:mb-12 lg:mb-12 whitespace-nowrap`
              ]}
            >
              <T.Head font="4">{subheading}</T.Head>

              <div css={[tw`w-32 relative block ml-5`]}>
                <LineSwipe
                  color={headingFontColor?.hex === `#ffffff` && `#ffffff`}
                  delay={500}
                  duration={2000}
                />
              </div>
            </header>

            <div
              css={[
                css`
                  color: ${headingFontColor?.hex || `#002762`};
                `,
                tw`mb-10 md:mb-12 font-bold`
              ]}
            >
              <T.Head font="2" level="2">
                {heading}
              </T.Head>
            </div>

            <T.Body
              _css={[
                css`
                  color: ${fontColor?.hex || `#000000`};
                `,
                tw`whitespace-pre-wrap`
              ]}
              font="1"
            >
              {body}
            </T.Body>

            <T.Body
              _css={[
                css`
                  color: ${fontColor?.hex || `#000000`};
                `,
                tw`whitespace-pre-wrap mt-14`
              ]}
              font="3"
            >
              {footnote}
            </T.Body>
          </div>
        </div>

        {/* table content */}
        <div
          css={[
            css`
              ${MEDIA_QUERIES.desktop} {
                order: ${isLeft ? `1` : `2`};
              }
            `,
            tw`col-span-12 md:col-span-6 h-full relative`,
            isLeft ? tw`md:col-start-1` : tw`md:col-start-7`
          ]}
        >
          {/* mobile (full) background */}
          <div
            css={[
              css`
                width: calc(100% + 4rem);
                left: -2rem;
                display: block;
                background-color: ${backgroundColor2?.hex || `#f5f6fa`};

                ${MEDIA_QUERIES.desktop} {
                  display: none;
                }
              `,
              tw`h-full absolute top-0 bottom-0`
            ]}
          />

          <div
            css={[
              css`
                width: 100%;
                padding-bottom: 4rem;
              `,
              tw`w-full h-full relative flex flex-col items-center justify-center pt-12`
            ]}
          >
            {/* table */}
            <div
              css={[
                css`
                  color: ${gridColor?.hex || `#ffffff`};

                  ${MEDIA_QUERIES.desktop} {
                    width: 100%;
                  }
                `,
                tw`w-full relative`
              ]}
            >
              <div
                css={[
                  css`
                    width: 100%;

                    @media screen and (max-width: 1024px) {
                      width: 100%;
                    }
                  `
                ]}
              >
                <div
                  css={[
                    css`
                      border-bottom: 1px solid;
                      display: grid;
                      grid-template-columns:
                        repeat(
                          ${table?.length - 1},
                          minmax(0, fit-content(10%))
                        )
                        1fr;
                    `
                  ]}
                >
                  {table?.map((column, columnIndex) =>
                    column?.rows?.map((cell, cellIndex) => (
                      <div
                        css={[
                          css`
                            grid-column: span 1 / span 1;
                            grid-column-start: ${columnIndex + 1};
                            grid-row-start: ${cellIndex + 1};
                          `,
                          cell?.length > 0 &&
                            css`
                              border-top: 1px solid;
                            `,
                          cellIndex === 0 && tw`border-0`
                        ]}
                      >
                        {cellIndex === 0 ? (
                          <T.Head
                            _css={[
                              css`
                                padding: 1rem 2.5rem 1rem 0rem;
                              `
                            ]}
                            font="5"
                          >
                            {cell}
                          </T.Head>
                        ) : (
                          <T.Body
                            _css={[
                              css`
                                padding: 1rem 1.25rem 1rem 0rem;

                                ${MEDIA_QUERIES.desktop} {
                                  padding: 1rem 2.5rem 1rem 0rem;
                                }
                              `,
                              columnIndex === 0 && tw`font-bold`
                            ]}
                            font={isDesktop() ? `2` : `3`}
                          >
                            {cell}
                          </T.Body>
                        )}
                      </div>
                    ))
                  )}
                </div>

                <T.Body _css={[tw`mt-6 md:mt-14`]} font="3">
                  {tableFootnote}
                </T.Body>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default TableTextSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment TableTextSectionFragment on SanityTableTextSection {
    _type

    backgroundColor {
      hex
      title
    }

    backgroundColor2 {
      hex
      title
    }

    headingFontColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    gridColor {
      hex
      title
    }

    subheading
    heading
    body
    alignment
    footnote

    table {
      rows
    }

    tableFootnote
  }
`;
